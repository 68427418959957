<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" >

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Booking Details" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Student Name</td>
                <td>{{  transaction_data.studentFullName ? transaction_data.studentFullName : "N/A" }}</td>
              </tr>
<!--              <tr>-->
<!--                <td class="font-semibold">Education Year</td>-->
<!--                  <td>{{ transaction_data.student.length > 0 && transaction_data.student[0].levelOfStudy ? transaction_data.student[0].levelOfStudy : "N/A"}}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="font-semibold">Languages</td>-->
<!--                <td><div v-if="transaction_data.student.length > 0"> <span v-for="(language,index) in transaction_data.student[0].languages" :key="language">{{ language.name }}<span v-if="transaction_data.student[0].languages.length-1 != index">, &nbsp;</span></span></div></td>-->
<!--              </tr>-->
              <tr>
                <td class="font-semibold">Date</td>
                <td>{{ transaction_data.date | date_formatter('DD/MM/YYYY') }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Time</td>
                <td>{{ transaction_data.startTime }} - {{ transaction_data.endTime}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Subjects</td>
                <td><span v-for="(subject,index) in transaction_data.subjects" :key="subject">{{ subject.name }}<span v-if="transaction_data.subjects.length-1 != index">,</span></span></td>
              </tr>
              <tr>
                <td class="font-semibold">Extra Comments</td>
                <td>{{ transaction_data.comment }}</td>
              </tr>


            </table>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Payment Details" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Tutor</td>
                <td>{{ transaction_data.tutorFullName ? transaction_data.tutorFullName : "N/A" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Payment By</td>
                <td>{{ transaction_data.tutorFullName ? transaction_data.tutorFullName : "N/A" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Tutor Location</td>
                <td v-html="transaction_data.tutoringLocation.displayAddress ? splitLineBreak(transaction_data.tutoringLocation.displayAddress) : 'N/A'"></td>
              </tr>
              <tr>
                <td class="font-semibold">Tutor/hr Rate</td>
                <td>${{ transaction_data.perHourRate ? transaction_data.perHourRate : "N/A"  | centToDollar }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Amt Without GST</td>
                <td>${{ transaction_data.amountWithoutGST ? transaction_data.amountWithoutGST : "N/A" | centToDollar}}</td>
              </tr>
              <tr>
                <td class="font-semibold">GST</td>
                <td>${{ transaction_data.GST ? transaction_data.GST : "N/A" | centToDollar}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Super Tutor Fee</td>
                <td>${{ transaction_data.superTutorFee | centToDollar }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Tutor Receiv. Fee</td>
                <td>${{ transaction_data.tutorActualReceivingFee | centToDollar }}</td>
              </tr>

            </table>
          </vx-card>
        </div>
      </div>


      <!-- Availabilities -->

    </div>
  </div>
</template>

<script>
  import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        transaction_data: {},
        user_not_found: false,
      }
    },
    computed: {

    },
    methods: {
      ...mapActions('transaction', ['fetchBookingTransactionById']),
      getTutorDetails() {
        let self = this;
        this.fetchBookingTransactionById(self.$route.params.bookingId).then(async res => {
          self.transaction_data = res.data.data;
          // await self.orderAvailabilities()
          console.log(self.user_data)
        })

      },
      splitLineBreak(value){
             return value.split(',').join("<br/>")
      },
  showDeleteSuccess() {
        this.$vs.notify({
          color: 'success',
          title: 'User Deleted',
          text: 'The selected user was successfully deleted'
        })
      }
    },
    async mounted() {
      await this.getTutorDetails()
    }
  }

</script>

<style lang="scss">
  #avatar-col {
    width: 10rem;
  }

  #page-user-view {
    table {
      td {
        vertical-align: top;
        min-width: 140px;
        padding-bottom: .8rem;
        word-break: break-all;
      }

      &:not(.permissions-table) {
        td {
          @media screen and (max-width:370px) {
            display: block;
          }
        }
      }
    }
  }

  // #account-info-col-1 {
  //   // flex-grow: 1;
  //   width: 30rem !important;
  //   @media screen and (min-width:1200px) {
  //     & {
  //       flex-grow: unset !important;
  //     }
  //   }
  // }


  @media screen and (min-width:1201px) and (max-width:1211px),
  only screen and (min-width:636px) and (max-width:991px) {
    #account-info-col-1 {
      width: calc(100% - 12rem) !important;
    }

    // #account-manage-buttons {
    //   width: 12rem !important;
    //   flex-direction: column;

    //   > button {
    //     margin-right: 0 !important;
    //     margin-bottom: 1rem;
    //   }
    // }

  }

</style>
